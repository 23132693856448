<template>
<Loader :visible="loading" fixed/>
  <div class="container-fluid side-width">
    <transition name="fade" mode="out-in" class="basic-user-data registration"
                                  v-if="showRegistrationForm">
       <div>
         <transition name="fade" mode="out-in">
            <h1 class="registrationHeading">Create an account</h1>
         </transition>
        <HeadingTt textAlign="center">
          (So we can save your project info!)
        </HeadingTt>
        <RegistrationNew ref="UserRegistration" required :next-step="handleRegister" :set-page-loading="(value) => loading=value"/>
      </div>
    </transition>
  <main v-else class="main page newproject">
    <section  class="section">
      <h1 class="section__heading">My Projects</h1>
      <div class="boards">
        <!-- Loop through user projects -->
        <div class="grid__inner 1" v-for="(project, index) in computedBoards" :key="index">
          <Board
            :boards="project"
            :fee="paymentFeeSelectionStatus"
            @view="viewBoard(project)"
            @continue="continueSurvey(project)"
            @measurements = "goToMeasurements(project)"
          />
        </div>

        <!-- TODO: REMOVE UTIL -->
        <!-- This loop can be used to preview boards to work on styling, see 'boards' in data -->
        <div class="grid__inner 2" v-for="(board, index) in boards" :key="index">
          <Board :board="board" />
        </div>

        <div class="boards__item boards__item--new">
          <router-link to="/quiz">
            <div class="boards__item--new__content">
              <img src="@/assets/images/add-circle.svg" />
              <h3 class="boards__item--new__cta">Start a New Project</h3>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </main>
</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import Board from '@/components/Board'
import Flickity from 'flickity'
// eslint-disable-next-line no-unused-vars
import FlickityFade from 'flickity-fade'
import 'flickity/dist/flickity.min.css'
import 'flickity-fade/flickity-fade.css'
import { boot } from '@intercom/messenger-js-sdk'
import Loader from '../components/Loader.vue'

export default {
  name: 'Boards',
  components: {
    Board,
    Loader
  },
  data () {
    return {
      // TODO: REMOVE UTIL - this can be used to manually add boards to work on styling
      boards: [
        // {
        //   projectName: 'Living Room',
        //   inProgress: false,
        //   isPending: true
        // }
      ],
      paymentFeeSelectionStatus: true,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['mySurveys', 'mySelections', 'userProfile']),
    alreadyPaid () {
      return this.userProfile?.selectionFeeOrderStatus === 'complete'
    },
    // Creates an array of objects which contains all aggregate information about a project
    computedBoards () {
      const boards = []
      if (!this.mySurveys || !this.mySelections) return boards
      this.mySurveys.forEach((survey) => {
        const board = survey
        const associatedSelection = this.mySelections.find((sel) => sel.id === survey.id)
        if (associatedSelection) {
          board.selection = associatedSelection
        }
        boards.push(board)
      })
      return boards
    }
  },

  methods: {
    ...mapMutations(['setPageLoading']),
    ...mapActions(['getMySurveys', 'getMySelections']),
    slideshow: function () {
      var boardSlideshows = document.getElementsByClassName('boards__item__media')
      var len = boardSlideshows !== null ? boardSlideshows.length : 0
      for (var i = 0; i < len; i++) {
        var boardSlideshow = boardSlideshows[i]
        // eslint-disable-next-line no-new
        new Flickity(boardSlideshow, {
          imagesLoaded: true,
          fade: true,
          prevNextButtons: false,
          pageDots: false,
          autoPlay: 2000,
          pauseAutoPlayOnHover: false,
          draggable: false,
          accessibility: false
        })
      }
    },

    viewBoard (board) {
      // This is a bit hacky, but we're delaying and showing the loader to hide the fact that
      // we're jumping immediately to the top of the page.
      this.setPageLoading(true)
      setTimeout(() => {
        this.$router.push({ path: `/results/${board.id}` })
      }, 300)
    },
    async refreshProjectData () {
      await this.getMySurveys()
      await this.getMySelections(true)
    },
    continueSurvey (survey) {
      this.$router.push({
        path: '/quiz',
        query: {
          id: survey.id,
          section: survey.currentSection,
          step: survey.currentStep || 0
        }
      })
    },
    isUserLoggedIn () {
      return this.currentUser?.uid != null
    },
    handleRegister () {
      this.showRegistrationForm = false
      const query = { ...this.$route.query }
      delete query.showRegistrationForm
      this.$router.replace({ path: this.$route.path, query })
    },
    async handleSurveyCompletion () {
      await this.refreshProjectData()
    },
    goToMeasurements (survey) {
      this.$router.push({
        path: '/measurements',
        query: {
          id: survey.id
        }
      })
    }
  },
  async mounted () {
    this.showRegistrationForm = this.$route.query.showRegistrationForm === 'true'

    await this.getMySurveys()
    await this.getMySelections(true) // Get detailed selection data so we can display furniture images

    boot({
      app_id: 'rokhj26f',
      user_id: this.userProfile?.firestore_reference_userId,
      name: this.userProfile?.displayname,
      email: this.userProfile?.email_address,
      created_at: this.userProfile?.date_createdAt
    })

    this.setPageLoading(false)
    this.slideshow()
    if (!this.alreadyPaid) {
      this.paymentFeeSelectionStatus = false
    }
  },
  unmounted () {
    if (window.Intercom) {
      window.Intercom('shutdown')
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        if (to.path === '/projects') {
          this.refreshProjectData()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.boards {
  display: contents;
  .boards__item {
    min-height: 400px;
    @include breakpoint($m-up) {
      grid-column: span 6;
    }

    &.boards__item--pending {
      a.button {
        pointer-events: none;
        color: $tt-grey-20;
        border-color: $tt-grey-20;
      }
    }

    .boards__item__date,
    .boards__item__room,
    .boards__item__media {
      @include h3;
      margin-bottom: calc(var(--leading) / 2);
    }

    .boards__item__media {
      mix-blend-mode: multiply;
    }

    .boards__item__media {
      height: 12rem;
      pointer-events: none;

      &.boards__item__media--empty {
        background: url('../assets/images/pending.svg') center center no-repeat;
        background-size: 6rem auto;
      }
    }

    .boards__item__media__item {
      height: 12rem;

      img {
        max-height: 100%;
      }
    }
  }
}

.registration{
  width: 800px;
  max-width: 100%;
  padding: 24px;
  margin: 0 auto;
}

.registrationHeading {
    @include h1;
    grid-column: span 12;
    margin-bottom: calc(var(--leading-h1) * .667);
    font-family: 'League Spartan' !important;
    font-weight: 600 !important;
    color: #0B1326 !important;
}
/*
Loading too much write
.boards__item__media .flickity-slider{
    display: flex;
    align-items: center;
    justify-content: center;
    transform: none !important;
}
.boards__item__media .flickity-cell{
  left:auto !important;
}*/
</style>
